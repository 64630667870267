@import '../../../../resources';
.formG {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #00000039;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  form {
    background: #fff;
    width: 100%;
    max-width: 400px;
    padding: 30px;
    border-radius: 10px;
    .close {
      text-align: right;
      svg {
        font-size: 20px;
        cursor: pointer;
      }
    }
    .top {
      @include Out;
      padding-bottom: 30px;
      h1 {
        font-size: 20px;
        padding-bottom: 5px;
      }
    }
    .card {
      width: 100%;
      margin-bottom: 10px;
      p {
        @include Out;
        font-size: 15px;
        padding-bottom: 5px;
      }
      select,
      input {
        width: calc(100% - 10px);
        height: 50px;
        border: 1px solid #afafaf;
        @include Out;
        font-size: 18px;
        color: #222;
        padding: 0 5px;
      }
      .imagee_label {
        height: 50px;
        border: 1px solid #afafaf;
        display: flex;
        align-items: center;
        padding: 0 5px;
        cursor: pointer p {
          display: flex;
          align-items: center;
          padding-bottom: 0;
        }
      }
      .inputy {
        display: none;
      }
    }
    button {
      background: #ffb703;
      border-radius: 4px;
      border: none;
      font-weight: 600;
      font-size: 16px;
      padding: 10px 20px;
      @include Out;
      color: #1c1c1c;
      margin-top: 20px;
    }
  }
}
