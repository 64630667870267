@import '../../../../resources';
.deposit {
  padding: 0 50px;
  padding-bottom: 100px;
  margin-bottom: 150px;
  @include mobile-800px {
    padding: 0 20px;
  }
  .home_tag {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;
    h1 {
      @include Out;
      font-size: 28px;
    }
    .home_select {
      @include mobile-600px {
        width: 100%;
        padding-top: 10px;
        text-align: right;
      }
      select {
        background: #ffffff;
        border: 0.5px solid #dedede;
        border-radius: 6px;
        padding: 10px 20px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
  .deposit-wrap {
    background: #ffffff;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.04);
    border-radius: 25.6px;
    padding: 25px;
    form,
    .form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .card {
        width: 45%;
        padding-bottom: 30px;
        &.ong {
          width: 100%;
        }
        @include mobile-800px {
          width: 100%;
        }
        p {
          @include Out;
          font-size: 20px;
          padding-bottom: 10px;
        }
        select,
        input {
          width: 100%;
          height: 50px;
          border: 1px solid #afafaf;
          @include Out;
          font-size: 18px;
          color: #222;
          padding: 0 5px;
        }
        .inputy {
          height: 50px;
          border: 1px solid #afafaf;
          display: flex;
          align-items: center;
          p {
            color: #222;
            font-size: 18px;
            padding: 0 10px;
          }
        }
        button {
          background: #ffb703;
          border-radius: 4px;
          border: none;
          font-weight: 600;
          font-size: 16px;
          padding: 10px 20px;
          @include Out;
          color: #1c1c1c;
          margin-top: 20px;
        }
      }
      .long {
        width: 100%;
        display: flex;
        align-items: center;
        @include mobile-800px {
          flex-wrap: wrap;
        }
        .left {
          width: 40%;
          @include mobile-800px {
            width: 100%;
          }
          img {
            width: 100%;
          }
        }
        .right {
          width: 58%;
          @include mobile-800px {
            width: 100%;
            text-align: center;
          }
          .one {
            font-weight: 600;
            font-size: 16px;
            line-height: 15px;
            color: #4a4a4a;
            @include Out;
            width: 80%;
            padding-bottom: 20px;
            span {
              text-transform: uppercase;
            }
            @include mobile-800px {
              width: 100%;
            }
          }
          .two {
            font-weight: 600;
            font-size: 16px;
            line-height: 15px;
            color: #4a4a4a;
            @include Out;

            padding-bottom: 10px;
          }
          h2 {
            color: #222;
            font-weight: 700;
            @include Out;
            padding-bottom: 20px;
          }
          .inputtype {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 57.6px;
            background: #f0f3ff;
            border-radius: 12.8px;
            padding: 0 20px;
            @include mobile-500px {
              overflow-x: auto;
              padding: 10px;
              justify-content: center;
              flex-wrap: wrap;
            }
            p {
              color: #4a4a4a;
              font-size: 16px;
              word-break: break-all;
              @include mobile-500px {
                padding-bottom: 10px;
              }
            }

            .button {
              background-color: #516ae4;
              color: #fff;
              border: none;
              padding: 10px;
              border-radius: 6px;
              font-size: 12px;
              max-width: 30px;
              cursor: pointer;
            }
          }
        }
      }
      .button {
        width: 100%;
        text-align: center;
        @include Out;
        p {
          font-size: 12px;
          padding-bottom: 10px;
        }
        button {
          background: #ffb703;
          border-radius: 4px;
          border: none;
          font-weight: 600;
          font-size: 20px;
          padding: 10px 20px;
          @include Out;
          color: #1c1c1c;
        }
      }
    }
    h2 {
      @include Out;
      font-size: 18px;
      padding-bottom: 10px;
    }
  }
}
