@import '../../../../resources';
.plans {
  display: flex;
  justify-content: center;
  padding: 0 50px;
  margin-bottom: 150px;
  @include mobile-800px {
    padding: 0 20px;
  }
  @include Out;
  .plans-wrap {
    width: 100%;
    .top {
      text-align: center;
      display: flex;
      justify-content: center;
      margin-bottom: 50px;

      h1 {
        width: 50%;
        color: #023047;
        font-size: 40px;
        @include mobile-600px {
          width: 100%;
        }
      }
    }
    .wrappe {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .crad {
        width: 30%;
        border-radius: 23px 23px 23px 23px;
        box-shadow: 0px 12px 50px 6px rgb(0 0 0 / 6%);
        text-align: center;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        @include mobile-1024px {
          width: 47%;
          margin-bottom: 30px;
        }
        @include mobile-600px {
          width: 100%;
        }
        .card-heaad {
          background: #023047;
          padding: 20px 10px;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          width: 100%;
        }
        .card-amount {
          padding: 30px 10px;
          h1 {
            font-weight: 700;
            font-size: 20px;
            color: #023047;
          }
        }
        ul {
          padding: 0 10px;
          li {
            padding-bottom: 20px;
            list-style: none;
            font-size: 14px;
          }
        }
        button {
          background: #ffb703;
          border-radius: 4px;
          border: none;
          height: 48px;
          width: 194px;
          font-weight: 600;
          font-size: 14px;
          @include Out;
          color: #1c1c1c;
          margin: 30px 0;
        }
      }
    }
  }
}
