@import '../../resources';
.terms {
  display: flex;
  justify-content: center;
  background: linear-gradient(#00000095, #00000095),
    url('../../images/stephen-dawson-qwtCeJ5cLYs-unsplash.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  @include Out;
  .terms-wrap {
    max-width: 1200px;
    width: 100%;
    padding: 50px 0;
    color: #fff;
    @include mobile-1200px {
      padding: 50px 20px;
    }
    .head {
      height: 40vh;
      a {
        color: #fff;
        font-size: 20px;
      }
      h1 {
        text-align: center;
        margin-top: 14vh;
        font-size: 40px;
      }
    }
  }
}
.terms-body {
  display: flex;
  justify-content: center;
  article {
    @include Out;
    max-width: 1200px;
    width: 100%;
    padding: 50px 0;
    @include mobile-1200px {
      padding: 50px 20px;
    }
    h2 {
      padding-bottom: 10px;
      padding-top: 30px;
    }
    p {
      padding-bottom: 14px;
      font-size: 16px;
      line-height: 40px;
    }
  }
}
