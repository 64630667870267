@import '../../resources';
.landing {
  .nav-wrap {
    display: flex;
    justify-content: center;
    background-color: #023047;
    @include Out;
    .navbar {
      max-width: 1200px;
      z-index: 10;
      width: 100%;
      background-color: #023047;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
    }
    .navbar a {
      list-style: none;
      text-decoration: none;
    }
    .navbar .nav_contain {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .navbar .logo {
      height: 80px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 1240px) {
        padding-left: 30px;
      }
    }
    .navbar .logo img {
      width: 200px;
    }
    .navbar .nav_list {
      display: flex;
      list-style: none;
      justify-content: space-evenly;
      align-items: center;
      @media screen and (max-width: 1240px) {
        padding-right: 30px;
      }
    }
    .navbar .nav_list li {
      position: relative;
      text-decoration: none;
      color: #fff;
      letter-spacing: 0.5px;
      font-size: 16px;
      cursor: pointer;
      font-weight: 500;
    }
    .navbar .nav_list a:nth-child(1) li {
      padding-right: 50px;
    }
    .navbar .nav_list a:nth-child(2) li {
      padding-right: 50px;
    }
    .navbar .nav_list a:nth-child(3) li {
      padding-right: 50px;
    }
    .navbar .nav_list li button {
      margin-left: 200px;
      background: #ffb703;
      border-radius: 4px;
      border: none;
      height: 40px;
      width: 90px;
      font-weight: 600;
      font-size: 20px;
      @include Out;
      color: #1c1c1c;
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
    .navbar .nav_list a:nth-child(6) li button {
      margin-left: 20px;
      background: none;
      color: #ffb703;
      border: 1px solid #ffb703;
    }

    .navbar .nav_list li:hover {
      color: #ffb703;
    }
    .navbar .nav_list li.active {
      color: #ffb703;
    }
    .navbar .nav_list li::after {
      content: '';
      display: block;
      height: 3px;
      width: 0%;
      background-color: #fff;
      position: absolute;
      transition: all ease-in-out 250ms;
      margin: 0 0 0 10%;
    }
    .hamburger {
      display: none;
    }
    .hamburger div {
      width: 30px;
      height: 3px;
      background: #f2f5f7;
      margin: 5px;
      transition: all 0.3s ease;
    }
    @media screen and (max-width: 1024px) {
      .navbar {
        position: fixed;
        height: 100px;
      }
      .hamburger {
        display: block;
        position: absolute;
        cursor: pointer;
        right: 5%;
        top: 50%;
        transform: translate(-5%, -50%);
        z-index: 2;
        transition: all 0.7s ease;
      }
      .navbar .logo {
        padding-left: 20px;
        padding-top: 20px;
      }
      .navbar .logo img {
        width: 130px;
      }
      .toggle .line1 {
        transform: rotate(-45deg) translate(-5px, 6px);
      }
      .toggle .line2 {
        transition: all 0.7s ease;
        width: 0;
      }
      .toggle .line3 {
        transform: rotate(45deg) translate(-5px, -6px);
      }
      .navbar .nav_list {
        position: fixed;
        background: #023047;
        height: 90vh;
        width: 100%;
        flex-direction: column;
        clip-path: circle(50px at 90% -20%);
        -webkit-clip-path: circle(50px at 90% -10%);
        transition: all 1s ease-out;
        pointer-events: none;
        text-align: center;
      }
      .navbar .nav_list.open {
        clip-path: circle(1000px at 90% -10%);
        -webkit-clip-path: circle(1000px at 90% -10%);
        pointer-events: all;
      }
      .navbar .nav_list li {
        opacity: 0;
      }

      .navbar .nav_list a:nth-child(1) li {
        transition: all 0.5s ease 0.2s;
        padding-right: 0;
      }
      .navbar .nav_list a:nth-child(2) li {
        transition: all 0.5s ease 0.4s;
        padding-right: 0;
      }
      .navbar .nav_list a:nth-child(3) li {
        transition: all 0.5s ease 0.6s;
        padding-right: 0;
      }
      .navbar .nav_list li:nth-child(4) {
        transition: all 0.5s ease 0.8s;
      }
      .navbar .nav_list li button {
        margin-left: 0;
      }
      .navbar .nav_list a:nth-child(6) li button {
        margin-left: 0;
      }
      .navbar .nav_list.open li {
        opacity: 1;
      }
    }

    @media screen and (max-width: 600px) {
      .navbar .logo {
        padding-top: 20px;
      }
      .navbar .logo img {
        width: 120px;
      }
    }
  }
  .hero {
    background-color: #023047;
    display: flex;
    justify-content: center;
    @include Out;
    @include mobile-1024px {
      padding-top: 100px;
    }
    .hero-wrap {
      max-width: 1200px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 50px 0;
      @include mobile-1200px {
        padding: 50px 20px;
      }
      .left {
        width: 45%;
        padding-top: 53px;
        padding-bottom: 30px;
        @include mobile-1024px {
          width: 100%;
          text-align: center;
          padding-top: 0;
        }
        h1 {
          font-size: 56px;
          font-weight: 600;
          color: #fff;
          @include mobile-1200px {
            font-size: 46px;
          }
          @include mobile-600px {
            font-size: 30px;
          }
        }
        p {
          font-weight: 400;
          font-size: 20px;
          color: #ffffff;
          padding-top: 23px;
          line-height: 30px;
          padding-bottom: 32px;
        }
        button {
          background: #ffb703;
          border-radius: 4px;
          border: none;
          height: 48px;
          width: 194px;
          font-weight: 600;
          font-size: 20px;
          @include Out;
          color: #1c1c1c;
          margin-bottom: 67px;
        }
        .buttons {
          padding-top: 30px;
          button {
            margin-right: 20px;
            background: #000;
            border-radius: 4px;
            height: 38px;
            width: 194px;
            font-weight: 600;
            font-size: 14px;
            @include Out;
            color: #fff;
            margin-bottom: 67px;
          }
        }
        .app {
          p {
            font-weight: 400;
            font-size: 20px;
            color: #ffffff;
            padding-bottom: 13px;
          }
        }
      }
      .right {
        width: 47%;
        @include mobile-1024px {
          width: 100%;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .about {
    display: flex;
    justify-content: center;
    @include Out;
    .about-wrap {
      max-width: 1200px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap-reverse;
      padding: 92px 0;
      @include mobile-1200px {
        padding: 50px 20px;
      }
      .left {
        width: 40%;
        @include mobile-1024px {
          width: 100%;
        }
        img {
          width: 100%;
          border-radius: 20px;
        }
      }
      .right {
        width: 53%;
        @include mobile-1024px {
          width: 100%;
          padding-bottom: 30px;
        }

        h1 {
          font-weight: 600;
          font-size: 40px;
          color: #121212;
          padding-bottom: 17px;
          @include mobile-500px {
            font-size: 30px;
          }
        }
        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;
          letter-spacing: 0.5px;
          color: #000;
          @include mobile-500px {
            font-size: 16px;
          }
        }
        .goal {
          margin-top: 59px;
          border-left: 4px solid #ffb703;
          padding-left: 16px;
          h2 {
            font-weight: 500;
            font-size: 20px;
            color: #121212;
            padding-bottom: 8px;
            @include mobile-500px {
              font-size: 30px;
            }
          }
        }
      }
    }
  }
  .plans {
    display: flex;
    justify-content: center;
    background: rgba(2, 48, 71, 0.102);
    @include Out;
    .plans-wrap {
      max-width: 1200px;
      width: 100%;
      padding: 92px 0;
      @include mobile-1200px {
        padding: 50px 20px;
      }
      .top {
        text-align: center;
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        h1 {
          width: 50%;
          color: #023047;
          font-size: 40px;
        }
      }
      .wrappe {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .crad {
          width: 30%;
          border-radius: 23px 23px 23px 23px;
          box-shadow: 0px 12px 50px 6px rgb(0 0 0 / 6%);
          text-align: center;
          overflow: hidden;
          @include mobile-1024px {
            width: 47%;
            margin-bottom: 30px;
          }
          @include mobile-600px {
            width: 100%;
          }
          .card-heaad {
            background: #023047;
            padding: 20px 10px;
            color: #fff;
            font-size: 20px;
            font-weight: 600;
          }
          .card-amount {
            padding: 30px 10px;
            h1 {
              font-weight: 700;
              font-size: 30px;
              color: #023047;
            }
          }
          ul {
            padding: 0 10px;
            li {
              padding-bottom: 20px;
            }
          }
          button {
            background: #ffb703;
            border-radius: 4px;
            border: none;
            height: 48px;
            width: 194px;
            font-weight: 600;
            font-size: 20px;
            @include Out;
            color: #1c1c1c;
            margin: 30px 0;
          }
        }
      }
    }
  }
  .faq {
    display: flex;
    justify-content: center;
    @include Out;
    .faq-wrap {
      max-width: 1200px;
      width: 100%;
      padding: 50px 0;
      @include mobile-1200px {
        padding: 50px 20px;
      }
      .top {
        text-align: center;
        padding-bottom: 56px;
        h1 {
          font-weight: 600;
          font-size: 40px;
          line-height: 30px;
          color: #121212;
          padding-bottom: 17px;
          @include mobile-500px {
            font-size: 30px;
          }
        }
        p {
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: #121212;
          @include mobile-500px {
            font-size: 16px;
          }
        }
      }
      .listt {
        display: flex;
        justify-content: center;
        .list {
          width: 100%;
          max-width: 1000px;
          .card {
            height: 50px;
            overflow: hidden;
            &.active {
              height: fit-content;
              overflow: unset;
            }
            header {
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid #f1f1f1;
              height: 48px;
              align-items: center;
              h2 {
                font-weight: 500;
                font-size: 20px;
                line-height: 100%;
                color: #000;
                @include mobile-500px {
                  font-size: 16px;
                }
              }
            }
            article {
              padding: 40px 0;
              font-weight: 300;
              font-size: 16px;
              line-height: 30px;
              color: #000;
              @include mobile-500px {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
.footer {
  display: flex;
  justify-content: center;
  background: #0f1010;
  @include Out;
  .footer-wrap {
    max-width: 1200px;
    width: 100%;
    padding: 50px 0;
    padding-top: 100px;
    color: #fff;
    @include mobile-1200px {
      padding: 50px 20px;
    }
    .top {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 151px;
      .card {
        width: 18%;
        &:nth-child(1) {
          width: 24%;
        }
        @media screen and (max-width: '1000px') {
          width: 30%;
          padding-bottom: 30px;
          &:nth-child(1) {
            width: 60%;
          }
        }
        @include mobile-500px {
          width: 100%;
          text-align: center;
          &:nth-child(1) {
            width: 100%;
          }
        }
        img {
          width: 201px;
          padding-bottom: 10px;
        }
        h2 {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          font-variant: small-caps;
          color: #afadad;
          padding-bottom: 16px;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          padding-bottom: 20px;
          word-wrap: break-word;
        }
        a {
          text-decoration: none;
          color: #fff;
          cursor: pointer;
        }
      }
    }
    .bottom {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #afadad;
      }
    }
  }
}
