* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
button {
  cursor: pointer;
}
body {
  background-color: #fff;
}
.react-toast-notifications__toast__content {
  font-family: 'Outfit' !important;
}
