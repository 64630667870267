@import '../../resources';
.auth {
  background: #023047;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  .center {
    width: 100%;
    max-width: 500px;
    .logo {
      padding-top: 72px;
      text-align: center;
      padding-bottom: 56px;
      img {
        width: 200px;
      }
    }
    .form {
      background: #ffffff;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      padding: 50px;
      @include mobile-500px {
        padding: 50px 20px;
      }
      .form_head {
        @include Out;

        padding-bottom: 21px;
        h1 {
          font-size: 39px;
        }
      }
      .form_body {
        .card {
          padding-bottom: 21px;
          input {
            background: #f1f5fd;
            border: 1px solid #d9d9d9;
            border-radius: 6px;
            width: 100%;
            padding: 17px;
            &::placeholder {
              @include Out;
              font-weight: 300;
              font-size: 14px;
            }
          }
        }
      }
      .form_bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 40px;
        .form_left {
          a {
            color: #000;
            &:hover {
              text-decoration: underline;
            }
            p {
              @include Out;
              font-size: 13px;
              font-weight: 500;
            }
          }
        }
        .form_right {
          button {
            display: flex;
            align-items: center;
            color: #fff;
            background: #ff8a00;
            border-radius: 21px;
            border: none;
            padding: 15px 30px;
            @include mobile-500px {
              padding: 15px 20px;
            }
            p {
              @include Out;
              font-size: 14px;
              font-weight: 500;
            }
            svg {
              font-size: 20px;
              margin-left: 21px;
            }
          }
        }
      }
    }
    .apply {
      display: flex;
      justify-content: center;
      width: 100%;
      .apply_cover {
        width: 90%;
        background: #e4eaf7;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        text-align: center;
        padding: 25px;
        @include Out;
        p {
          font-weight: 500;
          font-size: 14px;
          span {
            padding-left: 10px;
            a {
              color: #ff8a00;
            }
          }
        }
      }
    }
    .footers {
      text-align: center;
      @include Out;
      padding-top: 100px;
      p {
        font-weight: 500;
        color: #fff;
        font-size: 14px;
        span {
          padding-left: 10px;
          a {
            color: #ff8a00;
          }
        }
      }
    }
  }
}
