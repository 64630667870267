@import url('https://fonts.googleapis.com/css2?family=Lato&family=Outfit:wght@300;400;500;600&display=swap');

@mixin Out {
  font-family: 'Outfit', sans-serif;
}
@mixin Lato {
  font-family: 'Lato', sans-serif;
}

@mixin mobile-1200px {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin mobile-1024px {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin mobile-600px {
  @media (max-width: 600px) {
    @content;
  }
}
@mixin mobile-500px {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin mobile-420px {
  @media (max-width: 420px) {
    @content;
  }
}

@mixin mobile-800px {
  @media (max-width: 800px) {
    @content;
  }
}
